<template>
	<el-dialog :title="!dataForm.fileId ? '文件登记' : '文件修改'" style="z-index: 999995" :close-on-click-modal="false"
		:visible.sync="visible" width="870px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="95px" class="border-form">
			<el-row class="set_border">
				<!-- <el-col :span="8">
					<el-form-item label="发文类型">
						<el-tag type="danger">{{ fileKindName }}</el-tag>
					</el-form-item>
				</el-col> -->
				<el-col :span="16">
					<el-form-item label="标的物" prop="subjectNoList" class="item">
						<el-select style="width: 400px;" v-model="dataForm.subjectNoList" placeholder="" multiple clearable>
							<el-option v-for="item in subjectList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="15">
					<el-form-item label="文件编号" prop="fileNo" class="item">
						<el-input v-model="dataForm.fileNo" placeholder="" style="width:400px;"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="9">
					<div style="color:#cccfce;line-height: 14px;margin-top: 15px;margin-left: 18px;">此项将作为监理周月报、日志时提取使用
					</div>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="15">
					<el-form-item label="发文对象" prop="dispObject" class="item">
						<el-input v-model="dataForm.dispObject" style="width:400px;"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="15">
					<el-form-item label="主要内容" prop="fileTitle" class="item">
						<el-input style="width:400px;" v-model="dataForm.fileTitle"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="9">
					<div style="color:#cccfce;line-height: 14px;margin-top: 15px;margin-left: 18px;">此项将作为监理周月报、日志时提取使用
					</div>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="15">
					<el-form-item label="备注" prop="remark" class="item">
						<el-input style="width:400px;" v-model="dataForm.remark"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="9">
					<div style="color:#cccfce;line-height: 14px;margin-top: 15px;margin-left: 18px;">此项将作为监理周月报、日志时提取使用
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-tabs v-model="activeIndex" type="card" @tab-click="handleTabClick">
						<el-tab-pane :label="item.label" :key="item.name" v-for="item in tabs"
							:name="item.name"></el-tab-pane>
					</el-tabs>
					<div style="margin-top: -16px;">
						<el-card v-show="activeIndex === 'problemDesc'" class="set_card">
							<el-form-item class="setsummary_item" style="margin-bottom: 20px;">
								<span slot="label">概要</span>
									<el-input class="item_input" v-model="dataForm.summary"></el-input>
							</el-form-item>
							<div v-for="(item, index1) in dataForm.problemDescList" :key="index1">
								<el-col :span="24">
									<el-form-item class="setcontact_item">
										<span slot="label">问题{{ index1 + 1 }}</span>
										<el-input class="item_input"
											v-model="dataForm.problemDescList[index1].itemContext"></el-input>
										<el-button v-preventReClick type="text" size="small" class="prob_btn"
											@click="deleteProblemDesc(index1)">删除</el-button>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<div v-for="(detail, index2) in dataForm.problemDescList[index1].detailList"
										:key="index2" class="delete_input_bar">
										<el-form-item class="setcontact_item" style="margin-left: 40px;">
											<span slot="label">子项{{ index2 + 1 }}</span>
											<el-input class="detail_input"
												v-model="dataForm.problemDescList[index1].detailList[index2].detailContext"
												style="display: inline-block;"></el-input>
											<el-button v-preventReClick type="text" size="small" class="prob_btn"
												@click="deleteDetailDesc(index1, index2)">删除</el-button>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="24" style="margin-bottom: 20px;">
									<el-button v-preventReClick type="text" size="small" style="margin-left: 94px;"
										@click="addDetailDesc(index1)">添加子项</el-button>
								</el-col>
							</div>
							<el-col :span="24" style="margin-bottom: 10px;">
								<el-button v-preventReClick type="text" size="small"
									@click="addProblemDesc()">添加问题</el-button>
							</el-col>
							<el-col :span="8" style="margin-bottom: 20px;border:solid #dadada 1px">
								<el-form-item label="总监" prop="projectDirector" style="margin-left: 1px;" class="item">
									<el-input style="width: 145px;" v-model="dataForm.projectDirector"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8" style="border-top:solid #dadada 1px;border-bottom:solid #dadada 1px">
								<el-form-item label="监理工程师" prop="dispMan" class="item">
									<el-input style="width: 145px;" v-model="dataForm.dispMan"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8" style="border:solid #dadada 1px">
								<el-form-item label="签发日期" prop="dispTime" class="item">
									<el-date-picker style="width: 145px;" v-model="dataForm.dispTime" type="date"
										value-format="yyyy-MM-dd" placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
							</el-col>
						</el-card>
						<el-card v-show="activeIndex === 'problemPhoto'" class="set_card">
							<!-- <div v-for="(item, index1) in dataForm.photoList" :key="index1">
								<div class="border" style="margin-left: 80px;margin-bottom: 20px;">
									<div style="margin-left: 15px; margin-top: 15px;">
										<el-col :span="10">
											<el-upload ref="uploadImg" class="upload-demo" action="action"
												v-if="!dataForm.photoList[index1].photo01Uri" :http-request="uploadImg" drag
												:show-file-list=false :auto-upload="true"
												accept="image/jpeg,image/png,image/jpg"
												:data="{ 'type': '1', index: index1 }">
												<i class="el-icon-upload"></i>
												<div class="el-upload__text">将照片拖到此处，或<em>点击上传</em></div>
											</el-upload>
											<el-image style="width: 200px; height: 200px"
												:src="dataForm.photoList[index1].photo01Uri" fit="cover"
												v-if="dataForm.photoList[index1].photo01Uri && dataForm.photoList[index1].photo01Uri != ''"></el-image>
											<el-button v-preventReClick type="text" size="small"
												v-if="dataForm.photoList[index1].photo01Uri && dataForm.photoList[index1].photo01Uri != ''"
												@click="deletePhoto('1', index1)"
												style="margin-left: 170px;margin-top: -10px;">删除</el-button>
										</el-col>
										<el-col :span="10">
											<el-upload ref="uploadImg" class="upload-demo" action="action"
												v-if="!dataForm.photoList[index1].photo02Uri" :http-request="uploadImg" drag
												:show-file-list=false :auto-upload="true"
												accept="image/jpeg,image/png,image/jpg"
												:data="{ 'type': '2', index: index1 }">
												<i class="el-icon-upload"></i>
												<div class="el-upload__text">将照片拖到此处，或<em>点击上传</em></div>
											</el-upload>
											<el-image style="width: 200px; height: 200px"
												:src="dataForm.photoList[index1].photo02Uri" fit="cover"
												v-if="dataForm.photoList[index1].photo02Uri && dataForm.photoList[index1].photo02Uri != ''"></el-image>
											<el-button v-preventReClick type="text" size="small"
												v-if="dataForm.photoList[index1].photo02Uri && dataForm.photoList[index1].photo02Uri != ''"
												@click="deletePhoto('2', index1)"
												style="margin-left: 170px;margin-top: -10px;">删除</el-button>
										</el-col>
										<el-col :span="1" style="padding-top: 90px;">
											<el-button v-preventReClick type="text" size="small"
												@click="deletePhoto('0', index1)">删除</el-button>
										</el-col>
										<el-col :span="24" v-if="(!dataForm.photoList[index1].photo02Uri || dataForm.photoList[index1].photo02Uri == '') &&
											(!dataForm.photoList[index1].photo01Uri || dataForm.photoList[index1].photo01Uri == '')">
											<div style="height: 24px;"></div>
										</el-col>
										<el-col :span="24">
											<el-input class="photo_input"
												v-model="dataForm.photoList[index1].photoContext"></el-input>
										</el-col>
									</div>
								</div>
							</div>
							<el-button v-preventReClick type="text" size="small" @click="addPhoto()">添加照片</el-button> -->
							<el-row>
								<el-col :span="24">
									<el-form-item label="">
										<el-upload ref="uploadTempImg" class="upload-demo" action="action"
											style="margin-left: -115px;" :http-request="uploadTempImg" multiple drag
											:show-file-list=false :auto-upload="true"
											accept="image/jpeg,image/png,image/jpg">
											<i class="el-icon-upload"></i>
											<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
										</el-upload>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-table v-if="dataForm.photoList != undefined && dataForm.photoList.length > 0" border
										:data="dataForm.photoList" style="width: 100%;">
										<el-table-column type="index" label="序号" width="60" align="center">
										</el-table-column>
										<el-table-column label="图片" width="180" align="center">
											<template slot-scope="scope">
												<el-image style="height: 100px" :src="scope.row.photo01Uri"
													fit='contain'></el-image>
											</template>
										</el-table-column>
										<el-table-column label="描述" min-width="400px" align="center">
											<template slot-scope="scope">
												<el-input type="textarea" rows="2"
													v-model="dataForm.photoList[scope.$index].photoContext"
													placeholder="请填写"></el-input>
											</template>
										</el-table-column>
										<el-table-column label=" " width="100">
											<template slot-scope="scope">
												<el-button size="mini" type="danger"
													@click="delPhoto(scope.$index)">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</el-col>
							</el-row>
						</el-card>
						<el-card v-show="activeIndex === 'supervisionExpect'" class="set_card">
							<div v-for="(item, index1) in dataForm.expectList" :key="index1">
								<el-col :span="24">
									<el-form-item class="setcontact_item">
										<span slot="label">要求{{ index1 + 1 }}</span>
										<el-input class="item_input"
											v-model="dataForm.expectList[index1].itemContext"></el-input>
										<el-button v-preventReClick type="text" size="small" class="prob_btn"
											@click="deleteExpect(index1)">删除</el-button>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<div v-for="(detail, index2) in dataForm.expectList[index1].detailList" :key="index2"
										class="delete_input_bar">
										<el-form-item style="margin-left: 40px;" class="setcontact_item">
											<span slot="label">子项{{ index2 + 1 }}</span>
											<el-input class="detail_input"
												v-model="dataForm.expectList[index1].detailList[index2].detailContext"></el-input>
											<el-button v-preventReClick type="text" size="small" class="prob_btn"
												@click="deleteDetailExp(index1, index2)">删除</el-button>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="24" style="margin-bottom: 20px;">
									<el-button v-preventReClick type="text" size="small" style="margin-left: 94px;"
										@click="addDetailExp(index1)">添加子项</el-button>
								</el-col>
							</div>
							<el-col :span="24">
								<el-button v-preventReClick type="text" size="small" @click="addExpect()">添加要求</el-button>
							</el-col>
							<el-col :span="22" style="margin-bottom: 20px;border:solid #dadada 1px">
								<el-form-item label="总结" class="item">
									<el-input style="width: 590px" v-model="dataForm.contactSummary.itemContext"></el-input>
								</el-form-item>
							</el-col>
						</el-card>
						<el-card v-show="activeIndex === 'receiptComments'" class="set_card">
							<el-input type="textarea" rows="8" v-model="dataForm.receiverOptions"></el-input>
							<el-col :span="15" style="padding-left: 190px;margin-top: 30px; margin-bottom: 20px;">
								<el-form-item label="签收人" class="item" style="border:solid #dadada 1px">
									<el-input style="width: 180px;" v-model="dataForm.receiveMan"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="9" style="margin-top: 30px;">
								<el-form-item label="签收日期" class="item" style="border:solid #dadada 1px;border-left:0px">
									<el-date-picker style="width: 180px;" v-model="dataForm.receiveTime" type="date"
										value-format="yyyy-MM-dd" placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
							</el-col>
						</el-card>
						<el-card v-show="activeIndex === 'handleOpinions'" class="set_card">
							<el-input type="textarea" rows="8" v-model="dataForm.handleOptions"></el-input>
							<el-col :span="15" style="padding-left: 190px;margin-top: 30px; margin-bottom: 20px;">
								<el-form-item label="监理工程师" class="item" style="border:solid #dadada 1px">
									<el-input style="width: 180px;" v-model="dataForm.closeMan"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="9" style="margin-top: 30px;">
								<el-form-item label="日期" class="item" style="border:solid #dadada 1px;border-left:0px">
									<el-date-picker style="width: 180px;" v-model="dataForm.closeTime" type="date"
										value-format="yyyy-MM-dd" placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
							</el-col>
						</el-card>
						<el-card v-show="activeIndex === 'closeFiles'" class="set_card">
							<el-upload ref="uploadScanFile" class="" action="action" drag :http-request="uploadScanFile"
								multiple :show-file-list=false :auto-upload="true">
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							</el-upload>
							<el-col :span="24">
								<el-table v-if="dataForm.fileList && dataForm.fileList.length > 0" :data="dataForm.fileList"
									width="100px" style="margin-top: 10px;margin-bottom: 30px;" border
									:cell-style="{ padding: '0px' }">
									<el-table-column type="index" label="No." width="70px">
									</el-table-column>
									<el-table-column prop="fileName" label="文件名称" align="center" min-width="500px">
										<template slot-scope="scope">
											<el-input v-model="dataForm.fileList[scope.$index].fileName" placeholder="请填写"
												class="file_input"></el-input>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="120px" align="center">
										<template slot-scope="scope">
											<el-button size="mini" type="text" @click="delFile(scope.$index)">删除</el-button>
											<el-button size="mini" type="text"
												@click="viewFile(scope.row.filePathUrl, scope.row.fileName)">查看</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
						</el-card>
					</div>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<!-- <el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button> -->
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "contactdocument-add-or-update",
	data() {
		return {
			editorConfig: {
				UEDITOR_HOME_URL: "/UEditor/"
			},
			visible: false,
			inline: false,
			action: '',
			fileKindName: '',
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNoList: [],
				fileId: '',
				fileNo: '',
				fileKind: '',
				fileTitle: '',
				fileContent: '',
				dispObject: '',
				filePath: '',
				dispMan: '',
				dispTime: '',
				receiverName: '',
				remark: '',
				projectDirector: '',
				problemDescList: [],
				photoList: [],
				expectList: [],
				contactSummary: {
					itemContext: '',
					itemKind: '03'
				},
				receiverOptions: '',
				receiveMan: '',
				receiveTime: '',
				handleOptions: '',
				closeMan: '',
				closeTime: '',
				fileList: []
			},
			subjectList:[],
			activeIndex: 'problemDesc',
			tabs: [{ name: 'problemDesc', label: '问题描述' },
			{ name: 'problemPhoto', label: '问题照片' },
			{ name: 'supervisionExpect', label: '监理要求' },
			{ name: 'receiptComments', label: '签收/意见' },
			{ name: 'handleOpinions', label: '处理意见' },
			{ name: 'closeFiles', label: '封闭文件' }],
			dataRule: {
				subjectNoList: [
					{ required: true, message: " ", trigger: "blur" }
				],
				fileNo: [
					{ required: true, message: " ", trigger: "blur" }
				],
				fileTitle: [
					{ required: true, message: " ", trigger: "blur" }
				],
				fileContent: [
					{ required: true, message: "请输入文件内容", trigger: "blur" }
				],
				dispMan: [
					{ required: true, message: "请输入监理工程师", trigger: "blur" }
				],
				projectDirector: [
					{ required: true, message: "请输入项目总监", trigger: "blur" }
				],
				dispTime: [
					{ required: true, message: "请选择签发时间", trigger: "blur" }
				],
				dispObject: [
					{ required: true, message: " ", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id, fileKind, subjectNo) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNoList: [],
				fileId: '',
				fileNo: '',
				fileKind: '',
				fileTitle: '',
				fileContent: '',
				dispObject: '',
				filePath: '',
				dispMan: $common.getItem("userName"),
				dispTime: this.getCurrentTime(),
				receiverName: '',
				remark: '',
				projectDirector: '',
				problemDescList: [{
					itemContext: '',
					itemKind: '01',
					detailList: []
				}],
				photoList: [],
				expectList: [{
					itemContext: '',
					itemKind: '02',
					detailList: []
				}],
				contactSummary: {
					itemContext: '',
					itemKind: '03'
				},
				receiverOptions: '',
				receiveMan: '',
				receiveTime: '',
				handleOptions: '',
				closeMan: '',
				closeTime: '',
				fileList: [],
			};
			this.dataForm.fileId = id ? id : ''
			this.dataForm.fileKind = fileKind
			this.fileKindName = '监理联系单'
			if (this.dataForm.fileKind === '02') {
				this.fileKindName = '监理通知单'
			}
			this.activeIndex = 'problemDesc'
			this.getSubjectDropDown();
			if (this.dataForm.fileId) {
				this.$http({
					url: this.$store.state.httpUrl + `/business/supervisioncontactinfo/info/${this.dataForm.fileId}`,
					method: 'get',
					params: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataForm = data.body;
						if (data.body.expectList == undefined || data.body.expectList.length <= 0 || data.body.expectList == null) {
							this.dataForm.expectList = [{
								itemContext: '',
								itemKind: '02',
								detailList: []
							}]
						}

						if (data.body.problemDescList == undefined || data.body.problemDescList.length <= 0 || data.body.problemDescList == null) {
							this.dataForm.problemDescList = [{
								itemContext: '',
								itemKind: '01',
								detailList: []
							}]
						}
					}
				})
			} else {
				this.selectJob();
			}
			this.$nextTick(() => {
				this.visible = true;
				if (this.$refs["dataForm"] !== undefined) {
					this.$refs["dataForm"].resetFields();
				}
				let subjectNoList = []
				subjectNoList.push(subjectNo)
				this.$set(this.dataForm, 'subjectNoList', subjectNoList)
			});

		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		handleTabClick(tab) {
			this.activeIndex = tab.name
		},
		addProblemDesc() {
			let problem = {
				itemContext: '',
				itemKind: '01',
				detailList: []
			}
			this.dataForm.problemDescList.push(problem);
		},
		addDetailDesc(index1) {
			let detail = {
				detailContext: ''
			}
			this.dataForm.problemDescList[index1].detailList.push(detail)

		},
		deleteDetailDesc(index1, index2) {
			this.dataForm.problemDescList[index1].detailList.splice(index2, 1);
		},
		deleteProblemDesc(index1) {
			this.dataForm.problemDescList.splice(index1, 1);
		},
		addPhoto() {
			let photoItem = {
				fileId: this.dataForm.fileId,
				photo01Name: '',
				photo01Path: '',
				photo01Uri: '',
				photo02Name: '',
				photo02Path: '',
				photo02Uri: '',
				photoContext: ''
			}
			this.dataForm.photoList.push(photoItem)
		},
		delPhoto(index) {
			this.dataForm.photoList.splice(index, 1)
		},
		uploadTempImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadTempImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileKind,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.dataForm.photoList.push({
						photo01Name: res.body.fileName,
						photo01Uri: res.body.fileUri,
						photo01Path: res.body.fileSavePath,
						photoContext: this.extractFileName(res.body.fileName)
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		deletePhoto(type, index1) {
			if (type === '0') {
				this.dataForm.photoList.splice(index1, 1);
			} else if (type === '1') {
				this.dataForm.photoList[index1].photo01Name = ''
				this.dataForm.photoList[index1].photo01Path = ''
				this.dataForm.photoList[index1].photo01Uri = ''
			} else if (type === '2') {
				this.dataForm.photoList[index1].photo02Name = ''
				this.dataForm.photoList[index1].photo02Path = ''
				this.dataForm.photoList[index1].photo02Uri = ''
			}
		},
		deleteDetailExp(index1, index2) {
			this.dataForm.expectList[index1].detailList.splice(index2, 1);
		},
		deleteExpect(index1) {
			this.dataForm.expectList.splice(index1, 1);
		},
		addExpect() {
			let problem = {
				itemContext: '',
				itemKind: '02',
				detailList: []
			}
			this.dataForm.expectList.push(problem);
		},
		addDetailExp(index1) {
			let detail = {
				detailContext: ''
			}
			this.dataForm.expectList[index1].detailList.push(detail)
		},
		viewFile(url, filename) {
			filename = this.getSuffix(url, filename)
			this.getBlob(url, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		delFile(index) {
			this.dataForm.fileList.splice(index, 1)
		},
		selectJob() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/selectJob?workNo=" + $common.getItem("workNo"),
				method: "POST",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.projectDirector = data.body.inspectorInfo.personName;
				}
			});
		},
		selectFile() {
			window.open(this.dataForm.fileUri);
		},
		uploadImg(param) {
			let type = param.data.type;
			let index = param.data.index;
			const formData = new FormData();
			formData.append('file', param.file);
			if (this.$refs.uploadImg[0]) {
				this.$refs.uploadImg[0].clearFiles();
			}
			if (this.$refs.uploadImg[1]) {
				this.$refs.uploadImg[1].clearFiles();
			}

			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileKind,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					if (type === '1') {
						this.dataForm.photoList[index].photo01Name = res.body.fileName
						this.dataForm.photoList[index].photo01Path = res.body.fileSavePath
						this.dataForm.photoList[index].photo01Uri = res.body.fileUri
					} else if (type === '2') {
						this.dataForm.photoList[index].photo02Name = res.body.fileName
						this.dataForm.photoList[index].photo02Path = res.body.fileSavePath
						this.dataForm.photoList[index].photo02Uri = res.body.fileUri
					}
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileKind,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					let item = {
						fileId: this.dataForm.fileId,
						fileName: this.extractFileName(res.body.fileName),
						filePathUrl: res.body.fileUri,
						filePath: res.body.fileSavePath
					}
					this.dataForm.fileList.push(item)
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		//表单提交
		dataFormSubmit() {
			this.dataForm.workNo = $common.getItem("workNo")
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/supervisioncontactinfo/save";
					if (this.dataForm.fileId && this.dataForm.fileId != 0) {
						_url = "/business/supervisioncontactinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						}
					});
				}
			});
		}
	}
}
</script>
<style scoped>
.selItemInput.descAreaLg {
	width: 640px !important;
}

.detail_input /deep/ .el-input__inner {
	width: 560px !important;
}

.item_input /deep/ .el-input__inner {
	width: 600px !important;
}

.photo_input /deep/ .el-input__inner {
	width: 437px !important;
}

.file_input /deep/ .el-input__inner {
	width: 100% !important;
}

.prob_btn {
	margin-left: -40px;
	position: absolute;
}

.upload-demo /deep/ .el-upload-dragger {
	width: 300px;
	height: 100px;
	margin-left: 20px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin-top: 15px;
	line-height: 26px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 8px;
}

::v-deep .el-tabs__item.is-active {
	color: #1d1d86;
	background-color: #E1F1FF;
}

::v-deep .el-tabs__item:hover {
	color: #121213;
	background-color: #eceeed;
}

::v-deep .el-tabs--top .el-tabs__item.is-top {
	width: 134.5px;
	text-align: center;
}

.border {
	border: 1px solid #cbcbd3;
	height: 290px;
	width: 590px
}

::v-deep .el-input__inner {
	border: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	/* display: flex !important; */
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.setcontact_item /deep/ .el-form-item__label {
	margin-left: 2px !important;
	width: 93px !important;
	margin-bottom: 10px;
	background-color: #E1F1FF;
	color: #00428E !important;
	font-weight: bold !important;
}

.setsummary_item /deep/ .el-form-item__label {
	margin-left: 2px !important;
	width: 93px !important;
	margin-bottom: 15px;
	background-color: #E1F1FF;
	color: #00428E !important;
	font-weight: bold !important;
}
</style>
